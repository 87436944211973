import { Bucket, Playlist } from '../../models/models';

export namespace BucketAction {

  export class GetBuckets {
    static readonly type = '[Bucket] Get Buckets';
    constructor() {}
  }

  export class CreateBucket {
    static readonly type = '[Bucket] Create Bucket';
    constructor(
      public name: string
    ) {}
  }

  export class DeleteBucket {
    static readonly type = '[Bucket] Delete Bucket';
    constructor(
      public id: number
    ) {}
  }

  export class DeleteMultipleBuckets {
    static readonly type = '[Bucket] Delete Multiple Bucket';
    constructor(
      public ids: number[]
    ) {}
  }

  export class GetMoreBuckets {
    static readonly type = '[Bucket] Get More Buckets';
    constructor(
      public count?: number
    ) {}
  }

  export class AddPlaylistsToBucket {
    static readonly type = '[Bucket] Add Playlists To Bucket';
    constructor(
      public playlistIds: number[],
      public bucketId: number
    ) {}
  }

  export class DeletePlaylistFromBucket {
    static readonly type = '[Bucket] Remove Playlist From Bucket';
    constructor(
      public playlistId: number,
      public bucketId: number
    ) {}
  }

  export class RemoveTrackFromBucket {
    static readonly type = '[Bucket] Remove Track From Bucket';
    constructor(
      public trackId: number,
      public bucketId: number
    ) {}
  }

  export class UpdateBucketOnPlaylistChange {
    static readonly type = '[Bucket] Update Bucket on Playlist Change';
    constructor(
      public playlist: Playlist
    ) {}
  }

  export class UpdateBucketOnPlaylistDelete {
    static readonly type = '[Bucket] Update Bucket on Playlist Delete';
    constructor(
      public playlistIds: number[]
    ) {}
  }

  export class UpdateState {
    static readonly type = '[Bucket] Update Bucket State';
  }

  export class OverrideZoneTracksUsingBucketUntilNextTimeslot {
    static readonly type = '[Bucket] Manually Triggers Bucket to play and overwrite any playing playlists unitl next time slot';
    constructor(
      public zoneId: number,
      public bucketId: number,
      public forbidSimilar: boolean,
    ) {}
  }
}
