import * as moment from 'moment';

import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { Bucket, PaginationInfo } from '../../models/models';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { empty, of } from 'rxjs';

import { BucketAction } from './buckets.actions';
import { BucketService } from '../../services/bucket.service';
import { PlaylistAction } from '../playlists/playlists.actions';
import { PlaylistPrepare } from '../../utils/prepare-playlist';
import { StateReset } from 'ngxs-reset-plugin';
import { Injectable } from '@angular/core';

type Context = StateContext<BucketsStateModel>;

export interface BucketsStateModel {
  buckets: Bucket[];
  isBucketsLoaded: boolean;
  nextCursor: string;
  hasNextPage: boolean;
  errored: boolean;
}

@State<BucketsStateModel>({
  name: 'buckets',
  defaults: {
    buckets: null,
	  isBucketsLoaded: false,
    nextCursor: null,
  	hasNextPage: false,
    errored: false
  }
})
@Injectable()
export class BucketsState {

  constructor(
      private bucketService: BucketService,
      private store: Store
    ) {}

  @Action(BucketAction.GetBuckets)
  fetch({patchState}: StateContext<BucketsStateModel>) {
    return this.bucketService.getBucketConnection(null, 20)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          patchState({
            hasNextPage: paginationInfo.pageInfo.hasNextPage,
            nextCursor: paginationInfo.pageInfo.endCursor
          });
        }),
        map(({edges}) => edges.map(({node}) => node)),
        tap((buckets: Bucket[]) => {
					// create collage for bucket
					buckets = buckets.map(bucket => {
            bucket.artwork = BucketPrepare.bucketArtwork(bucket);
            bucket.playlists = bucket.playlists.map(playlist => {
              playlist.artwork = PlaylistPrepare.playlistArtwork(playlist);
              return playlist;
            });
            return bucket;
					});

					patchState({
						buckets,
						isBucketsLoaded: true
					})
        }),
        catchError(() => {
          patchState({ errored: true })
          return of([]);
        })
      );
  }

  @Action(BucketAction.GetMoreBuckets)
  getMore({getState, patchState}: StateContext<BucketsStateModel>, { count }: BucketAction.GetMoreBuckets) {
    const state = getState();
    return this.bucketService.getBucketConnection(state.nextCursor, count || 20)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          patchState({
            hasNextPage: paginationInfo.pageInfo.hasNextPage,
            nextCursor: paginationInfo.pageInfo.endCursor
          });
        }),
        map(({edges}) => edges.map(({node}) => node)),
        tap((buckets: Bucket[]) => {
          // create collage for bucket
          buckets = buckets.map(item => {
            item.artwork = BucketPrepare.bucketArtwork(item);
            item.playlists = item.playlists.map(playlist => {
              playlist.artwork = PlaylistPrepare.playlistArtwork(playlist);
              return playlist;
            });
            return item;
          });

          patchState({
            buckets: [...state.buckets, ...buckets]
          });
        }),
        catchError(() => {
          patchState({ errored: true })
          return of([]);
        })
      );
  }

  @Action(BucketAction.CreateBucket)
  create({getState, patchState}: StateContext<BucketsStateModel>, { name }: BucketAction.CreateBucket) {
    const state = getState();
    return this.bucketService.createBucket(name)
    .pipe(
      tap((bucket: Bucket) => {
        const buckets = BucketPrepare.sortBuckets([...state.buckets, bucket]);
        patchState({
          buckets
        });
      }),
      catchError(() => {
        patchState({ errored: true })
        return of([]);
      })
    );
  }

  @Action(BucketAction.DeleteBucket)
  delete({getState, patchState}: StateContext<BucketsStateModel>, { id }: BucketAction.DeleteBucket) {
    const state = getState();
    return this.bucketService.deleteBucket(id)
    .pipe(
      tap(() => {
        patchState({
          buckets: state.buckets.filter((item) => item.id !== id)
        });
      }),
      catchError(() => {
        patchState({ errored: true })
        return of([]);
      })
    );
  }

  @Action(BucketAction.DeleteMultipleBuckets)
  deleteMultiple({getState, patchState}: StateContext<BucketsStateModel>, { ids }: BucketAction.DeleteMultipleBuckets) {
    const state = getState();
    return this.bucketService.deleteMultipleBuckets(ids)
    .pipe(
      tap(() => {
        patchState({
          buckets: state.buckets.filter(({id}) => !ids.includes(id))
        });
      })
    );
  }

  @Action(BucketAction.AddPlaylistsToBucket)
  addPlaylists({getState, patchState}: StateContext<BucketsStateModel>, { playlistIds, bucketId }: BucketAction.AddPlaylistsToBucket) {
    const state = getState();

    return this.bucketService.addPlaylistsToBucket(playlistIds, bucketId)
    .pipe(
      tap((bucket) => {
        bucket.artwork = BucketPrepare.bucketArtwork(bucket);
        bucket.playlists = bucket.playlists.map(playlist => {
          playlist.artwork = PlaylistPrepare.playlistArtwork(playlist);
          return playlist;
        });
        const buckets = [...state.buckets];
        buckets.splice(state.buckets.findIndex((x) => x.id === bucket.id), 1, bucket);
        patchState({
          buckets: BucketPrepare.sortBuckets(buckets)
        });
      }),
      catchError(() => {
        patchState({ errored: true })
        return of([]);
      })
    )
  }

  @Action(BucketAction.DeletePlaylistFromBucket)
  removePlaylist({getState, patchState}: StateContext<BucketsStateModel>, {playlistId, bucketId}: BucketAction.DeletePlaylistFromBucket) {
    const state = getState();

    return this.bucketService.deletePlaylistFromBucket(playlistId, bucketId)
    .pipe(
      tap((bucket) => {
        bucket.artwork = BucketPrepare.bucketArtwork(bucket);
        bucket.playlists = bucket.playlists.map(playlist => {
          playlist.artwork = PlaylistPrepare.playlistArtwork(playlist);
          return playlist;
        });

        const buckets = [...state.buckets];
        buckets.splice(state.buckets.findIndex((x) => x.id === bucket.id), 1, bucket);
        patchState({
          buckets: BucketPrepare.sortBuckets(buckets)
        });
      }),
      catchError(() => {
        patchState({ errored: true })
        return of([]);
      })
    )
  }

  @Action(BucketAction.RemoveTrackFromBucket)
  removeTrack({getState, patchState, dispatch}: StateContext<BucketsStateModel>, {trackId, bucketId}: BucketAction.RemoveTrackFromBucket) {
    const state = getState();

    return this.bucketService.removeTrackFromBucket(trackId, bucketId)
    .pipe(
      map((bucket) => {
        const touchedPlaylists = [...state.buckets]
          .find(({id}) => bucketId === id).playlists
          .filter(({tracks})=> tracks.some(x => x.id === trackId))
          .map(({id}) => id);

        bucket.artwork = BucketPrepare.bucketArtwork(bucket);
        bucket.playlists = bucket.playlists.map(playlist => {
          playlist.artwork = PlaylistPrepare.playlistArtwork(playlist);
          return playlist;
        });

        const buckets = [...state.buckets];
        buckets.splice(state.buckets.findIndex((x) => x.id === bucket.id), 1, bucket);
        patchState({
          buckets: BucketPrepare.sortBuckets(buckets)
        });
        return touchedPlaylists;
      }),
      mergeMap((playlistsIds) => dispatch(new PlaylistAction.UpdatePlaylistsOnBucketTrackRemove(trackId, playlistsIds))),
      catchError(() => {
        patchState({ errored: true })
        return of([]);
      })
    )
  }

  @Action(BucketAction.UpdateBucketOnPlaylistChange)
  updateBucket({getState, patchState}: StateContext<BucketsStateModel>, {playlist}: BucketAction.UpdateBucketOnPlaylistChange) {
    const state = getState();

    let bucketsWithPlaylist = state.buckets
      .filter(bucket => bucket.playlists.some(({id}) => id === playlist.id))
      .map((bucket) => {
        const oldPlaylist = bucket.playlists.filter(x => x.id !== playlist.id);
        const newBucket = {
          ...bucket,
          playlists: [...oldPlaylist, playlist]
        }
        return {
          ...newBucket,
          artwork: BucketPrepare.bucketArtwork(newBucket),
          duration: BucketPrepare.bucketTrackDurationCalc(newBucket),
          trackCount: BucketPrepare.bucketTrackCountCalc(newBucket)
        }
      });
    patchState({
      buckets: BucketPrepare.sortBuckets([...state.buckets.filter(bucket => !bucket.playlists.some(({id}) => id === playlist.id)), ...bucketsWithPlaylist])
    })
  }

  @Action(BucketAction.UpdateBucketOnPlaylistDelete)
  updateOnPlaylistDelete({getState, patchState}: StateContext<BucketsStateModel>, {playlistIds}: BucketAction.UpdateBucketOnPlaylistDelete) {
    const state = getState();

    let bucketsWithPlaylist = state.buckets
      .filter(bucket => bucket.playlists.some(({id}) => playlistIds.includes(id)))
      .map((bucket) => {
        const newBucket = {
          ...bucket,
          playlists: bucket.playlists.filter(({id}) => playlistIds.includes(id))
        }
        return {
          ...newBucket,
          artwork: BucketPrepare.bucketArtwork(newBucket),
          duration: BucketPrepare.bucketTrackDurationCalc(newBucket),
          trackCount: BucketPrepare.bucketTrackCountCalc(newBucket)
        }
      });
    patchState({
      buckets: BucketPrepare.sortBuckets([...state.buckets.filter(bucket => !bucket.playlists.some(({id}) => playlistIds.includes(id))), ...bucketsWithPlaylist])
    })
  }

  @Action(BucketAction.UpdateState)
  updateState({getState, dispatch}: StateContext<BucketsStateModel>) {
    const state = getState();

    dispatch (new StateReset(BucketsState))
    .pipe(
      switchMap(() => {
        if(!!state.isBucketsLoaded) {
          return dispatch(new BucketAction.GetBuckets())
        }
        return empty();
      })
    )
    .subscribe();
  }

  @Action(BucketAction.OverrideZoneTracksUsingBucketUntilNextTimeslot)
  overrideZoneTracksUsingBucket({getState, patchState}: StateContext<BucketsStateModel>, { zoneId, bucketId, forbidSimilar }: BucketAction.OverrideZoneTracksUsingBucketUntilNextTimeslot) {
    const state = getState();
    return this.bucketService.overrideZoneTracksUsingBucketUntilNextTimeslot(zoneId, bucketId, forbidSimilar)
      .pipe(
        map((data: any) => data)
      );
  }

  @Selector()
  static buckets(state: BucketsStateModel): Bucket[] {
    return state.buckets;
  }

  @Selector()
  static isBucketsLoaded(state: BucketsStateModel): boolean {
    return state.isBucketsLoaded;
  }

  @Selector()
  static hasNext(state: BucketsStateModel): boolean {
    return state.hasNextPage;
  }

  @Selector()
  static errored(state: BucketsStateModel): boolean {
    return state.errored;
  }

  static bucketById(bucketId: number) {
    return createSelector([BucketsState], (state: BucketsStateModel) => {
      return state.buckets.find(({id}) => bucketId === id);
    });
  }
}

export default class BucketPrepare {
  static sortBuckets(buckets: Bucket[]) {
		return buckets.sort((b1, b2) => {
			if (!b1.updatedAt || b1.updatedAt.val) {
				return -1;
			}
			if (!b2.updatedAt || b2.updatedAt.val) {
				return 1;
			}

			return new Date(b2.updatedAt).getTime() - new Date(b1.updatedAt).getTime();
		});
	}

	static bucketArtwork(bucket: Bucket, count = 4) {
		const artwork = [];

		if (bucket.playlists) {
			const playlists = bucket.playlists;
			let trackIndex = 0, playlistIndex = 0;

			while (artwork.length < count && playlists.length !== playlistIndex) {
				if (playlists[playlistIndex].logoImageUrl ||
          !playlists[playlistIndex].tracks ||
					playlists[playlistIndex].tracks.length === 0 ||
					playlists[playlistIndex].tracks.length <= trackIndex
					) {
            if (playlists[playlistIndex].logoImageUrl) {
              artwork.push(playlists[playlistIndex].logoImageUrl);
            }
					playlistIndex++;
					trackIndex = 0;
					continue;
				}
				if (playlists[playlistIndex].tracks[trackIndex].artwork &&
					playlists[playlistIndex].tracks[trackIndex].artwork.artworkUrl100
				) {
					const src = playlists[playlistIndex].tracks[trackIndex].artwork.artworkUrl100;
					if (!artwork.includes(src)) {
						artwork.push(src);
					}
					if ((playlistIndex !== playlists.length - 1)) {
						playlistIndex ++;
						trackIndex = 0;
						continue;
					}
				}
				trackIndex++;
			}
		}
		return artwork;
  }

  static bucketTrackCountCalc(bucket: Bucket) {
    return bucket.playlists.reduce((res, playlist) => {
      return res + playlist.trackCount;
    }, 0);
  }

  static bucketTrackDurationCalc(bucket: Bucket) {
    const generalDuration = bucket.playlists.reduce((res, playlist) => {
      return res.add(moment.duration(playlist.duration));
    }, moment('00:00:00.000', 'HH:mm:ss'));
    return moment(generalDuration).format('HH:mm:ss');
  }
}
