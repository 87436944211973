export type SearchValues = 'TRACK' | 'PLAYLIST' | 'ARTIST' | 'ALBUM' | 'VIDEO_PLAYLIST' | 'CHANNEL_PLAYLIST';

export enum MediaTypes {
  VIDEO = 'VIDEO',
  AUDIO = 'MUSIC',
  CHANNEL = 'CHANNEL'
}

export enum TrackSource {
  ALBUM = 'album',
  PLAYLIST = 'playlist',
  BUCKET = 'bucket',
  SEARCH = 'search',
  ARTIST = 'artist'
}

export type ModalMode = 'EDIT' | 'CREATE';
export type ViewType = 'BLOCK' | 'LIST';
export enum ViewSwitcherState {
  INFO = 'INFO',
  CLIPS = 'CLIPS',
  TRACKS = 'TRACKS'
}

export enum CurationGenerateSwitcher {
  ALL = 'ALL',
  ORIGINAL = 'ORIGINAL',
  SIMILAR = 'SIMILAR',
  SPOTIFY = 'SPOTIFY'
}

export enum CurationView {
  SEARCH = 'SEARCH',
  SPOTIFY = 'SPOTIFY'
}

export enum TimeslotContainerType {
  PLAYLIST = 'PLAYLIST',
  BUCKET = 'BUCKET'
}

export enum PlaylistSourcePage {
  LIBRARY = 'library',
  DISCOVER = 'discover',
  MY_PLAYLIST = 'my-playlist-main-discover',
  NEW_PLAYLIST = 'new-playlist-main-discover',
  SEARCH = 'search'
}

export enum PlaylistMediaType {
  PLAYLIST = 'PLAYLISTS',
  VIDEO = 'VIDEO-PLAYLISTS'
}

export const MY_PLAYLISTS_VIEWS = [PlaylistSourcePage.DISCOVER, PlaylistSourcePage.LIBRARY, PlaylistSourcePage.MY_PLAYLIST];

export const MediaTypeCatalog = [
  {
    name: 'Audio',
    mediaType: MediaTypes.AUDIO
  },
  {
    name: 'Video',
    mediaType: MediaTypes.VIDEO
  },
  {
    name: 'Channel',
    mediaType: MediaTypes.CHANNEL
  }
]

export const UserRoles = {
  ca: 'Customer Admin',
  curator: 'Curator',
  scheduler: 'Scheduler',
  'no-role': 'No Role',
  user: 'User',
  manager: 'Manager',
}

export enum FileUploadingState {
  WAIT = 'wait',
  SENDING = 'sending',
  DONE = 'success',
  ERROR = 'error'
}

export const MimeTypeByExtension = {
  default: 'video/mp4',
  mp4: 'video/mp4',
  webm: 'video/webm',
  ogv: 'video/ogg',
  ogm: 'video/ogg',
  ogg: 'video/ogg',
  avi: 'video/avi',
  mov: 'video/quicktime'
}

export enum UploadMoreCount {
  PLAYLISTS_COUT = 20,
  BUCKET_COUNT = 10,
  TRACKS_COUNT = 20,
  CATALOGUE_PLAYLISTS_COUNT = 500
}

export const ConfirmDialogWidth = {
  medium: '400px'
}


export enum SpotifyLimits {
  PLAYLISTS_LIMIT = 50,
  RECOMMENDED_TRACKS_LIMIT = 100
}

export const SpotifyHardcodedOptions = {
  market: 'AU'
}

export const MAX_VIDEO_SIZE = 209715200;
export const MAX_PROMOTION_SIZE = 104857600;
export const SNACK_BAR_DURATION = 7000;
export const DATE_FORMAT_FOR_BACKEND = 'YYYY-MM-DD';
export const REPEAT_TIMES_FOR_PROMO_MINUTES = 5;
export const DEFAULT_DURATION_FOR_PROMO_SEC = 30;

export const WEEK_DAYS_CATALOG = [
  {
    title: 'Mon',
    value: 'mon'
  },
  {
    title: 'Tue',
    value: 'tue'
  },
  {
    title: 'Wed',
    value: 'wed'
  },
  {
    title: 'Thu',
    value: 'thu'
  },
  {
    title: 'Fri',
    value: 'fri'
  },
  {
    title: 'Sat',
    value: 'sat'
  },
  {
    title: 'Sun',
    value: 'sun'
  }
];

export enum PromotionStateOption {
  CUSTOM = 'custom',
  FOREVER = 'forever'
}

export enum UploadType {
  IMAGE = 'PICTURE',
  VIDEO = 'VIDEO'
}

export enum BlockStyle {
  INLINE = 'inline',
  COLUMN = 'column'
}

export enum PromotionScope {
  ZONE = 'ZONE',
  VENUE = 'VENUE'
}

export type DaysOfWeek = {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
}

export enum PromotionPolicyType {
  REPEAT_PERIOD = 'MIN_REPEAT_PERIOD_SECONDS',
  BEHAVIOUR = 'BEHAVIOUR',
  DURATION = 'DURATION'
}

export const SongColumns: string[] = [
  'name',
  'artist',
  'album',
  'duration',
  'options'
];

export const PlaylistColumns: string[] = [
  'name',
  'genre',
  'trackCount',
  'duration',
  'options'
];

export const DAY_SECONDS = 86400;
export const HOUR_SECONDS = 3600;

export const SCROLL_BOTTOM_OFFSET = 10;
