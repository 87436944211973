import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { empty, from, iif } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Promotion } from 'src/app/shared/models/models';
import { PromotionAction } from './promotions.actions';
import { PromotionService } from '../../services/promotion.service';
import { PromotionUploadResult } from '../../models/inner-models';
import { StateReset } from 'ngxs-reset-plugin';
import { UploadType } from '../../models/consts';
import { Injectable } from '@angular/core';

type Context = StateContext<PromotionsStateModel>;

export interface PromotionsStateModel {
  promotions: Promotion[];
  isPromotionsLoaded: boolean;
  lastPromotionActionedId: number;
  errorOnPromotionUpload?: string;
}

@State<PromotionsStateModel>({
  name: 'promotions',
  defaults: {
    promotions: null,
    isPromotionsLoaded: false,
    lastPromotionActionedId: null
  },
})
@Injectable()
export class PromotionsState {

  constructor(
    private promotionService: PromotionService
    ) {}

  @Action(PromotionAction.GetPromotions)
  fetch({ patchState }: Context) {
    return this.promotionService.getPromotions()
      .pipe(
        tap((promotions: Promotion[]) => {
          patchState({
            // @ts-ignore
            promotions: [ ...promotions ],
            isPromotionsLoaded: true
          })
        })
      );
  }

  @Action(PromotionAction.UploadPromotion)
  uploadPromotion({patchState}: Context, { promotionInput }: PromotionAction.UploadPromotion) {
    return iif(
      () => promotionInput.promotionType === UploadType.VIDEO,
      this.promotionService.uploadPromotionVideo(
        promotionInput.promotionFile,
        promotionInput.name
      ),
      this.promotionService.uploadPromotionImage(
        promotionInput.promotionFile,
        promotionInput.name,
        promotionInput.duration
      )
    )
    .pipe(
      tap((fileUploadResult: PromotionUploadResult) => {
        if (!!(+fileUploadResult.promotionId)) {
          patchState({
            lastPromotionActionedId: +fileUploadResult.promotionId,
            errorOnPromotionUpload: null
          })
        } else {
          patchState({
            errorOnPromotionUpload: `Error Id: ${fileUploadResult.uid}. ${fileUploadResult.message}`,
            lastPromotionActionedId: null
          })
        }
      })
    )
  }

  @Action(PromotionAction.SetPromotionSchedule)
  add({ getState, dispatch }: Context, { promotionVariables }: PromotionAction.SetPromotionSchedule) {
    const state = getState();

    return this.promotionService.setPromotionSchedule(promotionVariables)
    .pipe(
      tap(() => {
        if (state.isPromotionsLoaded) {
          dispatch(new PromotionAction.GetPromotion(promotionVariables.promotionId))
        }
      })
    );
  }

  @Action(PromotionAction.GetPromotion)
  getById({ setState, getState }: Context, { id }: PromotionAction.GetPromotion) {
    const state = getState();

    return this.promotionService.getPromotionById(id)
    .pipe(
      tap((promotion: Promotion) => {
        setState(
          patch({
            promotions: (state.promotions.some(({id}) => promotion.id === id)) ?
              updateItem<Promotion>(({id}) => promotion.id === id, promotion) :
              append([promotion])
          })
        );
      })
    );
  }

  @Action(PromotionAction.EditPromotion)
  edit({ setState, getState }: Context, { id, name }: PromotionAction.EditPromotion) {
    const state = getState();
    const changedPromo = {...state.promotions.find((promo) => promo.id === id)};

    return this.promotionService.editPromotion(id, name)
    .pipe(
      tap((res) => {
        if (res) {
          setState(
            patch({
              promotions: updateItem<Promotion>(
                (promo) => promo.id === id,
                { ...changedPromo, name }
              )
            })
          );
        }
      })
    );
  }

  @Action(PromotionAction.RemovePromoSchedule)
  removeSchedule({ dispatch }: Context, { ids }: PromotionAction.RemovePromoSchedule) {
    return from(ids)
    .pipe(
      tap((id) => {
        this.promotionService.removeSchedule(id)
        .subscribe(() => {
          dispatch(new PromotionAction.GetPromotions())
        })
      }),
    );
  }

  @Action(PromotionAction.ChangePromotionStatus)
  changeStatus({ setState, getState }: Context, { id, status }: PromotionAction.ChangePromotionStatus) {
    const state = getState();
    const changedPromo = {...state.promotions.find((promo) => promo.id === id)};
    delete changedPromo.schedules[0].weekDays['__typename'];
    changedPromo.schedules[0].policies = changedPromo.schedules[0].policies
      .map(({policyType, value, status}) => ({policyType, value, status}));
    return this.promotionService.setPromotionSchedule({
      ...changedPromo.schedules[0],
      promotionId: id,
      zoneIds: changedPromo.schedules.map(({zone}) => zone.id),
      status
    })
    .pipe(
      tap(() => {
        setState(
          patch({
            promotions: updateItem<Promotion>(
              (promo) => promo.id === id,
              {
                ...changedPromo,
                schedules: changedPromo.schedules.map((promotion) => ({ ...promotion, status }))
              }
            )
          })
        );
      })
    );
  }

  @Action(PromotionAction.UpdateState)
  updateState({getState, dispatch}: StateContext<PromotionsStateModel>) {
    const state = getState();

    dispatch (new StateReset(PromotionsState))
    .pipe(
      switchMap(() => {
        if(!!state.isPromotionsLoaded) {
          return dispatch(new PromotionAction.GetPromotions())
        }
        return empty();
      })
    )
    .subscribe();
  }

  @Action(PromotionAction.ReorderPromotionSchedules)
  orderPromotionSchedule({ dispatch }: Context, { promotionVariables }: PromotionAction.ReorderPromotionSchedules) {
    return this.promotionService
      .orderPromotionSchedule(promotionVariables)
      .pipe(
        tap((res) => {
          if (res) dispatch(new PromotionAction.GetPromotions());
          return res;
        })
      );
  }

  @Selector()
  static promotions(state: PromotionsStateModel): Promotion[] {
    return state.promotions;
  }

  @Selector()
  static isPromotionLoaded(state: PromotionsStateModel): boolean {
    return state.isPromotionsLoaded;
  }

  @Selector()
  static lastPromoActionedId(state: PromotionsStateModel): number {
    return state.lastPromotionActionedId;
  }

  static promotion(promoId: number) {
    return createSelector([PromotionsState], (state: PromotionsStateModel) => {
      return state.promotions ? state.promotions.find(({id}) => id === promoId) : null;
    });
  }
}
